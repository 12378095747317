:root {
  --font-neumatic: "Neumatic Compressed", sans-serif;
  --font-mondo: "Mondo", sans-serif;
  --font-homage: "HomageCondensed", serif;
  --font-inter: "Inter", sans-serif;
}

.neumatic {
  font-family: var(--font-neumatic);
}

.mondo {
  font-family: var(--font-mondo);
}

.homage {
  font-family: var(--font-homage);
}

.inter {
  font-family: var(--font-inter);
}

body {
  font-family: var(--font-mondo);
  color: var(--white);
}

i,
.italic {
  font-style: italic;
}

.thin {
  font-weight: 100;
}

.extra-light {
  font-weight: 200;
}

.light {
  font-weight: 300;
}

.regular {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.semi-bold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.extra-bold {
  font-weight: 800;
}

.black {
  font-weight: 900;
}

.uppercase {
  text-transform: uppercase;
}

.underlined {
  text-decoration: underline;
}

h1 {
  font-size: 9.6rem;
  line-height: 8rem;

  @media (--laptop) {
    font-size: 24rem;
    line-height: 24rem;
  }
}

.p1 {
  font-size: 12rem;
  line-height: 12rem;

  @media (--laptop) {
    font-size: 80rem;
    line-height: normal;
  }
}

.p2 {
  font-size: 12rem;
  line-height: 12rem;

  @media (--laptop) {
    font-size: 30rem;
    line-height: 24rem;
  }
}

.p3 {
  font-size: 8rem;
  line-height: 8rem;

  @media (--laptop) {
    font-size: 25.6rem;
    line-height: 21.6rem;
  }
}

.p4 {
  font-size: 12rem;
  line-height: 12rem;

  @media (--laptop) {
    font-size: calc(20 * var(--vrem));
    line-height: calc(20 * var(--vrem));
  }
}

.p5 {
  font-size: 8rem;
  line-height: 8rem;

  @media (--laptop) {
    font-size: 17rem;
    line-height: 17rem;
  }
}

.p6 {
  font-size: 7.2rem;
  line-height: 6.4rem;

  @media (--laptop) {
    font-size: 9.6rem;
    line-height: 8.6rem;
  }
}

.p7 {
  font-size: calc(1.2 * var(--vrem));
  line-height: calc(1.4 * var(--vrem));

  @media (--laptop) {
  }
}
