.loader {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--loader-z-index);
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  overflow: hidden;
  user-select: none;
  opacity: 1;
  color: var(--azure);
  background-color: var(--black);
  will-change: opacity;
  transition: opacity var(--transition-duration--fast)
    var(--transition-ease--in-out-quint);

  &--hidden {
    opacity: 0;
  }

  &__header {
    width: 100%;
    padding: 1.6rem var(--column);
    box-sizing: border-box;
    z-index: 2;
    position: relative;

    &__text {
      position: absolute !important;
      left: var(--column);
      bottom: calc(100% + 0.8rem);
    }

    &__logo {
      opacity: 0;
      pointer-events: none;
    }
  }
}
