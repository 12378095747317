.footer {
  padding: 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 5rem;
  z-index: var(--footer-z-index);
  opacity: 0;
  transition: opacity var(--transition-duration--slower)
    var(--transition-ease--out-expo);

  .loader-hidden & {
    opacity: 1;
  }

  @media (--laptop) {
    padding: 6rem 6.4rem;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
  }

  &__logo {
    text-align: center;
    font-size: 6rem;
    line-height: 5rem;

    @media (--laptop) {
      text-align: left;
      font-size: 15rem;
      line-height: 12rem;
    }
  }

  &__utils {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 5rem;
    font-size: 2.4rem;

    @media (--laptop) {
      align-items: flex-start;
      justify-content: space-between;
    }

    ul {
      display: flex;
      flex-direction: column;
      row-gap: 1.6rem;
      align-items: center;

      @media (--laptop) {
        align-items: flex-start;
      }
    }
  }
}
