.volume-uno {
  padding: 6rem 0;

  @media (--laptop) {
    padding: 12rem 0;
  }

  p:not(.volume-uno__title__image__credits) {
    mix-blend-mode: difference;
  }

  &__title {
    margin: 0 0.8rem;
    position: relative;

    @media (--laptop) {
      margin: 0 6.4rem;
    }

    > p {
      font-size: 12rem;
      line-height: 10rem;
      position: relative;
      z-index: 1;
      padding-bottom: 32rem;

      @media (--laptop) {
        font-size: 38rem;
        line-height: 31rem;
        padding-bottom: 19rem;
      }
    }

    &__image {
      display: flex;
      flex-direction: column;
      row-gap: 1.2rem;
      position: absolute;
      top: 3rem;
      right: 0;

      @media (--laptop) {
        top: 10rem;
        right: 25.5rem;
      }

      .image {
        width: 24rem;
        height: 33rem;

        @media (--laptop) {
          width: 49rem;
          height: 49rem;
        }
      }

      &__credits {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 0.8rem;

        @media (--laptop) {
          font-size: 1rem;
        }
      }
    }
  }

  &__text {
    font-size: 4.2rem;
    line-height: 4.6rem;
    padding: 0 0.8rem;

    @media (--laptop) {
      font-size: 8rem;
      line-height: 8.4rem;
      padding: 0 6.4rem;
    }
  }

  &__images {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    column-gap: var(--gutter);
    margin: var(--margin);

    &__image {
      display: inline-block;

      @media (--laptop) {
      }

      &:nth-child(5n + 1) {
        grid-column: 1 / 3;
        margin-top: 5rem;
        aspect-ratio: 12/15;

        @media (--laptop) {
        }
      }

      &:nth-child(5n + 2) {
        grid-column: 4 / 6;
        aspect-ratio: 1;

        @media (--laptop) {
        }
      }

      &:nth-child(5n + 3) {
        grid-column: 2 / 4;
        aspect-ratio: 13/16;
        margin-top: 10rem;

        @media (--laptop) {
        }
      }

      &:nth-child(5n + 4) {
        grid-column: 5 / 7;
        aspect-ratio: 13/16;

        @media (--laptop) {
        }
      }

      &:nth-child(5n + 5) {
        margin-top: 8rem;
        @media (--laptop) {
        }
      }
    }
  }
}
