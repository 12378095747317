:root {
  --marquee-translate-value: 50px;

  @media (--laptop) {
    --marquee-translate-value: 400px;
  }
}

.header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6.4rem 0;
  overflow: hidden;
  z-index: var(--header-z-index);
  opacity: 0;
  transition: opacity var(--transition-duration--slower)
    var(--transition-ease--out-expo);

  .loader-hidden & {
    opacity: 1;
  }

  @media (--laptop) {
    padding-bottom: 32rem;
  }

  &__logo {
    text-align: center;
  }

  &__marquee {
    width: 120%;
    overflow: hidden;
    transform: rotate(-9.4deg);

    @media (--laptop) {
      padding: 1.6rem 0;
    }

    .marquee__content {
      font-size: 2.4rem;
      font-weight: 500;

      @media (--laptop) {
        font-size: 4.8rem;
      }
    }
  }
}
