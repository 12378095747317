.open-call {
  padding: 15.6rem 0.8rem 6rem;
  display: flex;
  flex-direction: column;
  row-gap: 2.4rem;

  @media (--laptop) {
    padding: 15rem 6.4rem 12rem;
    row-gap: 9.2rem;
  }

  &__title {
    font-size: 6rem;
    line-height: 6.6rem;
    font-weight: 600;
    position: relative;

    @media (--laptop) {
      font-size: 16rem;
      line-height: 16rem;
    }

    &__marenna {
      position: relative;

      &::before {
        content: "";
        width: 80rem;
        height: 80rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0) rotate(0);
        transform-origin: center;
        background-image: url(/assets/icons/Panino_Rosa.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        will-change: transform;
        transition: transform 0.4s var(--transition-ease--out-expo);
        pointer-events: none;
      }

      &:hover {
        &::before {
          @media (--laptop) {
            transition: transform 0.6s cubic-bezier(0.34, 1.56, 0.64, 1);
            transform: translate(-50%, -50%) scale(1) rotate(-30deg);
          }
        }
      }
    }

    &__decorator {
      width: 19rem;
      height: 17rem;
      position: absolute;
      top: -12.4rem;
      right: 0;
      background-image: url(/assets/icons/red-star.svg);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;

      @media (--laptop) {
        width: 49rem;
        height: 44rem;
        top: -15rem;
      }
    }
  }

  &__text {
    font-size: 2.4rem;
    line-height: 2.8rem;

    @media (--laptop) {
      font-size: 4.8rem;
      line-height: 5.4rem;
      padding: 0 19rem;
    }
  }

  &__cta {
    font-size: 2.4rem;
    line-height: 2.8rem;
    display: flex;
    column-gap: 1.4rem;
    align-items: center;

    @media (--laptop) {
      font-size: 4.8rem;
      line-height: 5.4rem;
      column-gap: 6.4rem;
      padding: 0 19rem;
    }

    &::before {
      content: "";
      width: 2.4rem;
      height: 1.8rem;
      background-image: url(/assets/icons/right-arrow.svg);
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;

      @media (--laptop) {
        width: 4.4rem;
        height: 3rem;
      }
    }
  }
}
