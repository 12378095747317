@font-face {
  font-family: "Neumatic Compressed";
  src: url("/assets/font/NeumaticCompressed-Regular.woff2") format("woff2"),
    url("/assets/font/NeumaticCompressed-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "HomageCondensed";
  src: url("/assets/font/HomageCondensed-Black.woff2") format("woff2"),
    url("/assets/font/HomageCondensed-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
