:root {
  --white: #ffffff;
  --black: #000000;
  --azure: #d3dcde;
  --red: #bd1622;
}

.c-black {
  color: var(--black);
}

.c-white {
  color: var(--white);
}

.c-azure {
  color: var(--azure);
}

.c-red {
  color: var(--red);
}

.bg-azure {
  background-color: var(--azure);
}

.bc-black {
  background-color: var(--black);
}

.bc-red {
  background-color: var(--red);
}

.bc-white {
  background-color: var(--white);
}
