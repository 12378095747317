.intro {
  padding: 0 0.8rem;

  @media (--laptop) {
    padding: 0 6.4rem;
  }

  p {
    font-size: 3rem;
    line-height: 3.6rem;

    @media (--laptop) {
      font-size: 4.8rem;
      line-height: 5.4rem;
    }
  }
}
