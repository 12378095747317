.text-with-dynamic-font-size {
  max-width: 100%;
  overflow: hidden;

  &__rows {
    width: fit-content;

    &__row {
      white-space: nowrap;
    }
  }
}
