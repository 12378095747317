.image {
  position: relative;

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-position: center;
    object-fit: cover;

    @media (--laptop) {
    }
  }
}
